import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import Branch from "@/store/modules/Branch";
import CustomerStore from "@/store/modules/Customer";
import SalesInvoces from "@/store/modules/SalesInvoice";
import PurchaseRequisition from "./modules/PurchaseRequisition";
config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    Branch,
    CustomerStore,
    SalesInvoces,
    PurchaseRequisition
  },
  state : {
    access_token : ''
  },
  actions : {
    updateAccessToken(token){
      this.state.access_token = token
    }
  }
});



export default store;
